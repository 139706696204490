
/**
 * @name: 售后管理-商品售后
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 售后管理-商品售后
 * @update: 2024-05-13 11:37
 */
import { Vue, Component } from "vue-property-decorator"
import {
  refundPageApi,
  refundAuditApi,
  refundExportApi,
  refundStatusDetailApi,
  refundForceRefundApi
} from '@/apis/aftermarket/product'
import {
  IAfterMarketProductParams,
  IAfterMarketProduct,
  IAfterMarketStatusItem
} from '@/apis/aftermarket/product/types'
import config from "@/config"
import type { ICrudOption } from "@/types/m-ui-crud"
import { deepCopy, exportFile } from "@/utils/common";

@Component({})
export default class aftermarketProduct extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: IAfterMarketProduct[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: IAfterMarketProductParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<IAfterMarketProduct> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menuWidth: 150,
    column: [
      {
        label: '售后单号',
        prop: 'refundSn',
        align: 'left',
        width: 180,
        search: true
      },
      {
        label: '会员手机号',
        prop: 'phone',
        search: true,
        align: 'center',
        width: 150,
        slot: true
      },
      {
        label: '关联订单ID',
        prop: 'orderId',
        search: true,
        align: 'center',
        width: 150
      },
      {
        label: '售后商品',
        prop: 'productName',
        align: 'center',
        overHidden: true
      },
      {
        label: '零售价（元）',
        prop: 'retailPrice',
        align: 'center',
        width: 120
      },
      {
        label: '销售数量',
        prop: 'payQty',
        align: 'center'
      },
      {
        label: '退款金额（元）',
        prop: 'refundPrice',
        align: 'center',
        width: 120
      },
      {
        label: '退款原因',
        prop: 'reasonRefund',
        align: 'center',
        width: 150,
        overHidden: true
      },
      {
        label: '补充凭证',
        prop: 'refundPic',
        align: 'center',
        type: 'image',
        imgSuffix: ';',
        width: 120
      },
      {
        label: '审核状态',
        prop: 'productRefundStatus',
        align: 'center',
        type: 'select',
        slot: true,
        search: true,
        dicData: [
          {
            label: '待审核',
            value: 1
          },
          {
            label: '已通过',
            value: 2
          },
          {
            label: '已拒绝',
            value: 3
          },
          {
            label: '强制通过',
            value: 4
          }
        ]
      },
      {
        label: '申请时间',
        prop: 'refundApplyTime',
        align: 'center',
        type: 'daterange',
        search: true,
        width: 150
      },
      {
        label: '说明',
        prop: 'refundErrorRemarks',
        align: 'center',
        width: 150,
        overHidden: true
      },
    ]
  }

  auditDetailDialog = false

  auditDialog = false

  selectId = ''

  radioForm: any = {
    audit: 1,
    remarks: ''
  }

  auditStatusList: IAfterMarketStatusItem[] = []

  openAuditDetailDialog (row: IAfterMarketProduct) {
    refundStatusDetailApi(row.id).then(e => {
      this.auditStatusList = e
      this.auditDetailDialog = true
    })
  }

  openAuditDialog (row: IAfterMarketProduct) {
    this.selectId = row.id
    this.radioForm.audit = 1
    this.radioForm.remarks = ''
    this.auditDialog = true
  }

  auditEnter (done: Function, loading: Function) {
    // @ts-ignore
    this.$refs.remarkForm.validate(valid => {
      if (valid) {
        refundAuditApi({
          orderDtlId: this.selectId,
          refundExamineReason: this.radioForm.remarks,
          examineStatus: this.radioForm.audit
        }).then(e => {
          if (e) {
            this.$message.success('操作成功!')
            this.getList()
            done()
          }
        }).finally(() => {
          loading()
        })
      } else {
        loading()
      }
    })
  }

  auditClose () {
    this.radioForm.audit = 1
    this.radioForm.remarks = ''
    this.selectId = ''
  }

  /**
   * 打开强制退款弹窗
   * @param row 行数据
   */
  openRefundDialog (row: IAfterMarketProduct) {
    this.$confirm('是否确认强制通过退款？', '提示', {}).then(() => {
      // 强制退款
      refundForceRefundApi(row.id, '').then(e => {
        if (e) {
          this.$message.success('操作成功!')
          this.getList()
        }
      })
    }).catch(() => {})
  }

  exportExcel () {
    const query: IAfterMarketProductParams = deepCopy(this.queryParam)
    if (query.refundApplyTime && query.refundApplyTime.length) {
      query.refundStartApplyTime = query.refundApplyTime[0]
      query.refundEndApplyTime = query.refundApplyTime[1]
    } else {
      query.refundStartApplyTime = ''
      query.refundEndApplyTime = ''
    }
    delete query.refundApplyTime

    refundExportApi(query).then(e => {
      exportFile(e, '商品售后.xlsx')
    })
  }

  getList () {
    this.tableLoading = true

    const query: IAfterMarketProductParams = deepCopy(this.queryParam)
    if (query.refundApplyTime && query.refundApplyTime.length) {
      query.refundStartApplyTime = query.refundApplyTime[0]
      query.refundEndApplyTime = query.refundApplyTime[1]
    } else {
      query.refundStartApplyTime = ''
      query.refundEndApplyTime = ''
    }
    delete query.refundApplyTime

    refundPageApi(query).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  created () {
    this.getList()
  }
}
