/**
 * @name: 售后管理-商品售后接口文件
 * @author: Mobai
 * @date: 2024-05-13 11:37
 * @description： 售后管理-商品售后接口文件
 * @update: 2024-05-13 11:37
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import { IAfterMarketProduct, IAfterMarketProductParams, IAfterMarketStatusItem } from "./types";


export const refundPageApi = (params: IAfterMarketProductParams) => get<IPageRes<IAfterMarketProduct[]>>('/admin/refund/query', params)

export const refundAuditApi = (data: Partial<IAfterMarketProduct>) => postJ('/admin/refund/auditing', data)

export const refundExportApi = (params: IAfterMarketProductParams) => get('/admin/refund/export', params, 'blob')

export const refundStatusDetailApi = (orderDtlId: string) => get<IAfterMarketStatusItem[]>('/admin/refund/getDtlList', {orderDtlId})

export const refundForceRefundApi = (orderDtlId: string, refundExamineReason: string) => postJ("/admin/refund/force/refund", {orderDtlId, refundExamineReason})
